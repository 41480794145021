import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Headline from '../components/Headline';
import Layout from '../components/Layout';

import 'prism-themes/themes/prism-synthwave84.css';

import './prism-theme-override.css';


const breakPoints = {
  sm: 320,
  md: 800,
  lg: 1200,
  xl: 1940,
};


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  font-family: 'Karla', sans-serif;
  height: ${props => props.height || '100%'};
  @media screen and (min-width: ${breakPoints.sm}px) {
    width: 100%;
  }
  @media screen and (min-width: ${breakPoints.md}px) {
    max-width: 788px;
  }
  @media screen and (min-width: ${breakPoints.lg}px) {
    max-width: 1088px;
  }
  @media screen and (min-width: ${breakPoints.xl}px) {
    max-width: 1920px;
  }
`;
Container.displayName = 'Container';


/* eslint-disable react/no-danger */
export default ({ data }) => {
  const post = data.markdownRemark; // data.markdownRemark holds our post data
  return (
    <Layout>
      <div style={{ marginTop: '10px' }}>
        <Headline text={post.frontmatter.title} color="#57C7FF" />
        <div
          style={{
            marginTop: '30px',
          }}
        >
          <Container>
            <Helmet title={`Eric Gossett - ${post.frontmatter.title}`} />
            <div
              style={{
                backgroundColor: 'rgba(0, 19, 53, 0.5)',
                padding: '15px',
                borderRadius: '3px',
              }}
            >
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </div>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        image {
          childImageSharp{
              sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
              }
          }
        }
      }
    }
  }
`;
